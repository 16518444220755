/**
*
* Toggle the accordion menu items
*
*/
let $accordionMenuToggle = $('[data-accordion-menu-toggle]')
let $socialLinks = $('[data-social-links]')
$accordionMenuToggle.on('click', function(e){
    e.preventDefault()
    $('[data-accordion-menu-dropdown]').addClass('hidden').hide()
    $('[data-accordion-menu-toggle]').addClass('opacity-50')
    $(this).removeClass('opacity-50');
    $(this).find('[data-chevron-down]').addClass('hidden')
    $(this).find('[data-chevron-up').removeClass('hidden')
    $socialLinks.addClass('hidden');
    var $accordionItem = $(this).parent().find('[data-accordion-menu-dropdown]').slideDown()
})


/**
*
* Product detail accordion
*
*/
let $accordion = $('[data-accordion-item]')
$.each($accordion, function(index, item) {
    // Add some unique Id's to each accordion
    let itemId = Math.random().toString(36).substr(2, 9)
    $(item).find('[data-accordion-title]').attr('data-accordion-title', itemId)
    $(item).find('[data-accordion-content]').attr('data-accordion-content', itemId)
    // Setup an event handler
    let $toggle = $(item).find('[data-accordion-title]')
    $toggle.on('click', function(e) {
        
        // Get the accordion id
        let accordionId = $(this).data('accordion-title')
        let $target = $('[data-accordion-content="'+accordionId+'"]');
        
        // Trigger an event
        $(document).trigger('accordion:change')
        
        // Show or hide?
        if ($target.hasClass('hidden')) {
            $('[data-accordion-content="'+accordionId+'"]').removeClass('hidden')
            $('[data-accordion-title="'+accordionId+'"]').addClass('is-active')
        } else {
            $('[data-accordion-content="'+accordionId+'"]').addClass('hidden')
            $('[data-accordion-title="'+accordionId+'"]').removeClass('is-active')
        }
    })
})