// $(function() {

//     $(document).on('click', '.js--service-selector', function() {

// 		var service = $(this).data('service'),
// 			quote_types = service.split(',');

// 		// Update form
// 		$('#quote_type').val(service);

// 		// Deactivate all icons
// 		$('.js--service-selector-bg').removeClass('bg-gradient-to-b from-purple to-violet');
// 		$('.js--service-selector-icon').removeClass('text-white').addClass('text-purple');
// 		$('.js--service-selector-title').removeClass('text-white').addClass('text-black');
// 		$('.js--service-selector-text').removeClass('text-white').addClass('text-mid-grey');

// 		// Activate selected
// 		$('.js--service-selector-bg[data-service="'+service+'"]').addClass('bg-gradient-to-b from-purple to-violet');
// 		$('.js--service-selector-icon[data-service="'+service+'"]').addClass('text-white').removeClass('text-purple');
// 		$('.js--service-selector-title[data-service="'+service+'"]').addClass('text-white').removeClass('text-black');
// 		$('.js--service-selector-text[data-service="'+service+'"]').addClass('text-white').removeClass('text-mid-grey');

// 		// Hide all
// 		$('.js--quote-section')
// 			.removeAttr('style')
// 			.addClass('hidden');

// 		// Un-set required attributes
// 		$('.js--purchase-data, .js--sale-data')
// 			.attr('required',false)
// 			.attr('aria-required',false);

// 		// Un-hide and activate selected services
// 		$.each(quote_types, function( index, value ) {
// 			// Un-hide
// 			$('#quote-'+value).fadeIn()
// 			// Set required
// 			$('.js--'+value+'-data')
// 				.attr('required',true)
// 				.attr('aria-required',true);
// 		});

// 		// Show contact details and button
// 		if (quote_types.length > 0) {
// 			$('#quote-details, #quote-confirmation').removeClass('hidden');
// 		}

// 	});


// })


