window.addressLookup = {

    init: function() {

		if ($('#find-address').length) {

			console.log('Initialising postcode lookup...')

			require('ideal-postcodes-autocomplete')

			// Live key
			var pcKey = window.idealPostcodesKey;
			console.log(pcKey);
			// Test key
			//var pcKey = 'iddqd'
			const controller = new IdealPostcodes.Autocomplete.Controller({
				api_key: pcKey,
				inputField: "#find-address",
				outputFields: {
					building_number: "#number",
					thoroughfare: "#street",
					line_1: '#address1',
					line_2: '#address2',
					post_town: "#town_city",
					postcode: "#postcode",
					county: "#county"
				},
				onAddressSelected: function(address) {
					console.log(address)
					// hide the container again
					if ($('[data-address-container]').is(':visible')) {
						$('[data-address-container]').slideToggle()
					}
					// show the selected address
					$('[data-selected-address]')
						.removeClass('hidden')
						.find('[data-address]')
						.html(address.suggestion)
				},
				onSuggestionError: function(error) {
					console.log('Suggestion error')
					console.log(error)
					$('[data-address]').slideToggle()
					$('[data-selected-address]').addClass('hidden')
				},
				onSearchError: function(error) {
					console.log('Search error')
					console.log(error)
					$('[data-address]').slideToggle()
					$('[data-selected-address]').addClass('hidden')
				}
			});

			// Clear the address and focus the input again
			$('[data-address-clear]').on('click', function(event) {

				// Prevent.
				event.preventDefault();

				// Clear the current selected address from UI
				$('[data-selected-address]')
					.addClass('hidden')
					.find('[data-address]')
					.empty()

				// Clear the inputs
				$('#find-address').focus()
				$('#number').val('')
				$('#address1').val('')
				$('#address2').val('')
				$('#street').val('')
				$('#town_city').val('')
				$('#county').val('')
				$('#postcode').val('') 

			})

		}
	}
};

window.addressLookup.init();